@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
  font-size: 16px;
  font-family: "Inter", sans-serif;
  color: #323b4b;
  font-weight: 500;
  background-color: #f2f5f5;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}

strong {
  font-weight: 600;
}

.ReactCollapse--collapse {
  transition: height 300ms;
}

.hidden {
  display: none !important;
}

@media screen and (max-width: 1023px) {
  .lg-hidden {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .mb-hidden {
    display: none !important;
  }
}

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #247083;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
}

#nprogress .spinner {
  display: none;
}

.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

a {
  color: #247083;
  transition: all 0.2s linear;
}

a:hover {
  color: #8a94a6;
  text-decoration: underline;
}

a:hover svg {
  color: #8a94a6;
}

input[type="file"] {
  padding: 0.8125rem 1rem;
}

.hide-on-mobile {
  @media (max-width: 767px) {
    display: none;
  }
}

:root {
  --rt-color-white: #fff;
  --rt-color-dark: #247083;
  --rt-color-success: #8dc572;
  --rt-color-error: #be6464;
  --rt-color-warning: #f0ad4e;
  --rt-color-info: #337ab7;
  --rt-opacity: 1;
}

#banto-tooltip {
  z-index: 99999;
}

.react-tooltip__tooltip_KtSkBq {
  z-index: 99999;
  border-radius: 5px;
  max-width: 280px;
}

.cursor-pointer {
  cursor: pointer;
}

#pie-chart-tooltip {
  border-radius: 2px;
  background-color: #4e5d78;
  overflow: hidden;
  font-size: 12px;
  font-weight: 500;
  padding: 2px 6px;
  color: #fff;
  transition: all 0.25s ease-in;
}

#pie-chart-tooltip table thead tr td {
  padding: 0;
}
